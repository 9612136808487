import { Button, Typography } from "antd";
import { useState } from "react";
import { Layout, ArticlesTable, AddNewDrawer } from "..";
import { useNews } from "../api/getNews";

interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [edited, setEdited] = useState<any>(null);

  const newsQuery = useNews({});
  const handleOpen = (tmp: any) => {
    if (tmp) setEdited(tmp);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setEdited(null);
  };

  if (newsQuery.isLoading) return <Typography.Text>Loading...</Typography.Text>;
  if (newsQuery.isError || !newsQuery.data)
    return <Typography.Text>Wystąpił błąd podczas pobierania gazetek</Typography.Text>;
  return (
    <Layout>
      <div style={{ margin: "24px 0" }}>
        <Button onClick={() => handleOpen(null)} type="primary">
          Dodaj gazetkę/news
        </Button>
      </div>
      <ArticlesTable handleOpen={handleOpen} news={newsQuery.data} />
      {isOpen ? <AddNewDrawer isOpen={isOpen} handleClose={handleClose} edited={edited} /> : null}
    </Layout>
  );
};
