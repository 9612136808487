import "./style.less";
import useScrollBlock from "../../hooks/useScrollBlock";
import { useEffect } from "react";
import { LocalizationType } from "../../types";
interface LocalizationModalProps {
  setLocalization: (value: LocalizationType) => void;
}

export const LocalizationModal: React.FC<LocalizationModalProps> = ({ setLocalization }) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    blockScroll();
    return allowScroll;
  });
  return (
    <div className={`localization-modal`}>
      <div className="localization-modal-content">
        <div className="localization-modal-content-title">
          <h1>Witaj na oficjalniej stronie Famix</h1>
          <p>Wybierz oddział:</p>
        </div>

        <button
          className="localization-modal-btn localization-modal-btn-rzeszow"
          onClick={() => setLocalization("Podkarpackie/Centrala")}
          style={{
            backgroundImage: `url(/templates/landing/rzeszow.jpg)`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {/* <MdDomain /> */}
          {/* <img src={RzeszowBgc} alt="podkarpacie" /> */}
          <span>Podkarpacie/Centrala</span>
        </button>
        <button
          className="localization-modal-btn localization-modal-btn-krakow"
          onClick={() => setLocalization("Małopolskie")}
          style={{
            backgroundImage: `url(/templates/landing/krakow.jpg)`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {/* <BiGitBranch /> */}
          {/* <img src={KrakowBgc} alt="malopolska" /> */}
          <span>Małopolska</span>
        </button>
      </div>
    </div>
  );
};
