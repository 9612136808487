import "./style.less";
import { Spin } from "antd";
import useScrollBlock from "../../hooks/useScrollBlock";
import { useEffect } from "react";
interface FullPageLoadingProps {}

export const FullPageLoading: React.FC<FullPageLoadingProps> = () => {
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    blockScroll();
    return () => allowScroll();
  }, []);
  return (
    <div className="full-page-loading">
      <Spin />
    </div>
  );
};
