import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ConfigProvider } from "antd";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../lib/react-query";
import { FullPageLoading } from "../components";
import plPL from "antd/lib/locale/pl_PL";
import moment from "moment";
import GeolocationProvider from "./geolocation";
import HtmlProvider from "./html";
import "moment/locale/pl";

moment.locale("pl");

type AppProviderProps = {
  children: React.ReactNode;
};

const ErrorFallback = ({ ...props }: any) => {
  const is404 = props.error && props.error.response && props.error.response.status === 404;

  return (
    <div>
      <h2>{is404 ? "Nie ma takiej strony" : "Ooops, something went wrong :("} </h2>
      {is404 ? (
        <a href="/"> Wróć na stronę główną!</a>
      ) : (
        <button onClick={() => window.location.assign(window.location.origin)}>Refresh</button>
      )}
    </div>
  );
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const handleFirstTab = (e: any) => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = () => {
    document.body.classList.remove("user-is-tabbing");

    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab);
    return () => window.removeEventListener("keydown", handleFirstTab);
  }, []);
  return (
    <React.Suspense fallback={<FullPageLoading />}>
      <ErrorBoundary FallbackComponent={(props) => <ErrorFallback {...props} />}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={plPL}>
            <HtmlProvider>
              <Router>
                <GeolocationProvider>{children}</GeolocationProvider>
              </Router>
            </HtmlProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
