import { Menu, Grid } from "antd";
import { navData } from "./data";
import { useLocation } from "react-router-dom";
import { scrollWithNavOffset, scrollWithOffset } from "../../../utils/scroll";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { Accessibility } from "../../Accessibility";
import { LocalizationSelect } from "../..";
const { useBreakpoint } = Grid;
interface NavigationProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const Navigation: React.FC<NavigationProps> = ({ isOpen, handleClose }) => {
  const [active, setActive] = useState("/");
  const breakpoints = useBreakpoint();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname) return;
    setActive(location.pathname);
  }, [location]);
  return (
    <div className={`public-layout-nav-menu ${isOpen ? "public-layout-nav-menu-active" : ""}`}>
      <Menu
        selectedKeys={[active]}
        mode={breakpoints.lg ? "horizontal" : "inline"}
        //@ts-ignore
        getPopupContainer={() => document.getElementById("public-layout-nav")}
      >
        {navData.map((submenu) =>
          submenu.children ? (
            <Menu.SubMenu key={submenu.href} title={submenu.name}>
              {submenu.children
                ? submenu.children.map((menu, index) => (
                    <Menu.Item key={menu.href} className="menu-sublink">
                      <HashLink
                        smooth
                        to={menu.href}
                        scroll={(el) => (index === 0 ? scrollWithOffset(-600)(el) : scrollWithNavOffset(el))}
                        onClick={handleClose}
                      >
                        {menu.name}
                      </HashLink>
                    </Menu.Item>
                  ))
                : null}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={submenu.id}>
              <HashLink smooth to={submenu.href} scroll={(el) => scrollWithOffset(-600)(el)} onClick={handleClose}>
                {submenu.name} {submenu.id === 25 && <img src={"/templates/landing/ue_nav.png"} alt="Unia Europejska" />}
              </HashLink>
            </Menu.Item>
          )
        )}
      </Menu>
      {breakpoints.lg ? null : (
        <div className="footer-wrapper">
          <div style={{ display: "flex", alignItems: "center", gap: "8px", padding: "8px 12px" }}>
            <Accessibility />
          </div>
          <footer className="public-layout-nav-menu-footer">
            <p>Copytight 2021</p>

            <LocalizationSelect />
          </footer>
        </div>
      )}
    </div>
  );
};
