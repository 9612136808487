import { useQuery } from "react-query";
import api from "../../../lib/withAxios";
import { QueryConfig } from "../../../lib/react-query";
import { News } from "../types";

export const getNews = (): Promise<News[]> => {
  return api.get(`/strona/getGazetki`).then((res) => res.data);
};

type UseNewsOptions = {
  config?: QueryConfig<typeof getNews>;
};

export const useNews = ({}: UseNewsOptions) => {
  return useQuery({
    queryKey: "news",
    queryFn: () => getNews(),
  });
};
