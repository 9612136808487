interface SlideProps {
  data: { id: number; name: string };
}

export const Slide: React.FC<SlideProps> = ({ data }) => {
  return (
    <div className="landing-page-banner-slide">
      <h1>{data.name}</h1>
    </div>
  );
};
