import "./style.less";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Typography, message, Radio } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { Link } from "react-router-dom";
import axios from "axios";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";

const NewClientSchema = Yup.object().shape({
  companyName: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  street: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  city: Yup.string().min(2, "Minimum 2 znaki!").max(100, "Maximum 100 znaków!").required("Pole obowiązkowe"),
  phone: Yup.string()
    .matches(/^(?:([\+]?[(]?[0-9]{2}[)]?)?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{6})?$/im, "Nieprawidłowy numer!")
    .required("Pole obowiązkowe"),
  email: Yup.string().email("Niepoprawny email").required("Pole obowiązkowe"),
  remarks: Yup.string().max(800, "Maximum 800 znaków!"),
});

interface NewClientProps {}

interface NewClientValues {
  companyName: string;
  street: string;
  city: string;
  email: string;
  phone: string;
  remarks: string;
  type: 0 | 1;
}

const initialValues: NewClientValues = {
  companyName: "",
  street: "",
  city: "",
  email: "",
  phone: "",
  remarks: "",
  type: 0,
};

export const NewClient: React.FC<NewClientProps> = () => {
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeCaptcha = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaptcha(e.target.value);
  };

  const validateUserCaptcha = () => {
    if (validateCaptcha(captcha) === true) {
      loadCaptchaEnginge(6);
      setCaptcha("");
      return true;
    } else {
      loadCaptchaEnginge(6);
      setCaptcha("");
      return false;
    }
  };
  const handleSubmit = async (values: NewClientValues, actions: any) => {
    if (!validateUserCaptcha()) return message.error("Kod niepoprawny");
    setLoading(true);
    try {
      const newMessage = {
        nazwaFirmy: values.companyName,
        ulica: values.street,
        kodPocztowy: values.city,
        email: values.email,
        numerTelefonu: values.phone,
        typKlienta: values.type === 1 ? "Klient hurtowy" : "Klient detaliczny",
        tresc: values.remarks,
      };

      await axios.post("https://test-e.famix.com.pl/api/public/klient/wyslijWiadomoscToFamix", newMessage);
      setLoading(false);
      actions.resetForm();
      message.success("Wiadomość wysłana");
    } catch (err: any) {
      setLoading(false);
      message.error("Ups! Coś poszło nie tak");
    }
  };
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  return (
    <section className="new-client" id="nowy-klient">
      <h2>Formularz zgłoszeniowy dla nowych klientów</h2>
      <Formik validationSchema={NewClientSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleChange, errors, touched, setFieldValue }) => (
          <FormikForm className="new-client-form-wrapper">
            <div className="new-client-form-wrapper-half">
              <Form.Item label="Nazwa firmy" required>
                <Input
                  placeholder="Nazwa firmy"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                />
                {errors.companyName && touched.companyName ? (
                  <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                    {errors.companyName}
                  </Typography.Text>
                ) : null}
              </Form.Item>

              <Form.Item label="Ulica / miejscowość" required>
                <Input placeholder="Ulica/miejscowość" name="street" value={values.street} onChange={handleChange} />
                {errors.street && touched.street ? (
                  <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                    {errors.street}
                  </Typography.Text>
                ) : null}
              </Form.Item>
            </div>

            <div className="new-client-form-wrapper-half">
              <Form.Item label="Kod pocztowy / miasto" required>
                <Input placeholder="Kod pocztowy / miasto" name="city" value={values.city} onChange={handleChange} />
                {errors.city && touched.city ? (
                  <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                    {errors.city}
                  </Typography.Text>
                ) : null}
              </Form.Item>
              <Form.Item label="Numer telefonu" required>
                <Input
                  type="tel"
                  placeholder="Wprowadź swój nr. telefonu (123456789)"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                {errors.phone && touched.phone ? (
                  <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                    {errors.phone}
                  </Typography.Text>
                ) : null}
              </Form.Item>
            </div>

            <div className="new-client-form-wrapper-half">
              <Form.Item label="Email" required className="new-client-form-wrapper-half-input">
                <Input
                  type="email"
                  placeholder="Podaj swój email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email ? (
                  <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                    {errors.email}
                  </Typography.Text>
                ) : null}
              </Form.Item>
            </div>
            <Form.Item>
              <Radio.Group value={values.type} onChange={(e) => setFieldValue("type", e.target.value)} name="type">
                <Radio value={0}>Klient detaliczny</Radio>
                <Radio value={1}>Klient hurtowy</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Dodatkowe uwagi, zapytanie" className="new-client-form-wrapper-half-input">
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 8 }}
                placeholder="Wprwoadź treść wiadomości..."
                name="remarks"
                value={values.remarks}
                onChange={handleChange}
              />
              {errors.remarks && touched.remarks ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.remarks}
                </Typography.Text>
              ) : null}
            </Form.Item>

            <Form.Item className="new-client-form-buttons">
              <div style={{ maxWidth: "200px", margin: "12px 0" }}>
                <LoadCanvasTemplate reloadText="Wygeneruj nowy kod" />
                <Input placeholder="Wprowadź kod z obrazka" value={captcha} onChange={handleChangeCaptcha} />
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className="new-client-form-send"
                disabled={loading}
                loading={loading}
              >
                Wyślij
              </Button>
            </Form.Item>
            <Typography.Text style={{ display: "block", maxWidth: "600px" }}>
              Wysyłając zgłoszenie z formularza wyrażają Państwo zgodę na przetwarzanie danych osobowych{" "}
              <Link to="/kontakt/rodo">(informacje szczegółowe)</Link>.
            </Typography.Text>
          </FormikForm>
        )}
      </Formik>
    </section>
  );
};
