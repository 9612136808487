import "./style.less";
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useAuth } from "../../../../providers/auth";

interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { handleLogout } = useAuth();
  return (
    <div className="admin-layout">
      <header className="admin-layout-header">
        <div>
          <Typography.Text className="admin-logo">Panel Admina</Typography.Text>
          <Button onClick={handleLogout} icon={<LogoutOutlined />}>
            Wyloguj się
          </Button>
        </div>
      </header>

      <main className="admin-layout-content">{children}</main>
    </div>
  );
};
