import "./style.less";
import { Typography } from "antd";
import { HashLink } from "react-router-hash-link";
import { scrollWithNavOffset } from "../../../../utils/scroll";

interface RodoContentProps {}
export const RodoContent: React.FC<RodoContentProps> = () => {
  return (
    <section className="rodo-page" id="rodo">
      <h2>Ochrona danych osobowych RODO</h2>

      <ul>
        <li>
          <HashLink smooth to={"#ogolna-klauzula"} scroll={(el) => scrollWithNavOffset(el)}>
            OGÓLNA KLAUZULA INFORMACYJNA RODO (poniżej)
          </HashLink>
        </li>
        <li>
          <a href="/files/rodo/01-KLAUZULA.pdf" target={"_blank"}>
            KLAUZULA INFORMACYJNA RODO DLA KONTRAHENTÓW, KLIENTÓW FAMIX
          </a>
        </li>
        <li>
          <a href="/files/rodo/02-KLAUZULA.pdf" target={"_blank"}>
            KLAUZULA INFORMACYJNA RODO DLA PRACOWNIKÓW, WSPÓŁPRACOWNIKÓW, REPREZENTANTÓW KONTRAHENTÓW FAMIX
          </a>
        </li>
        <li>
          <a href="/files/rodo/03-DANE-KONTAKTOWE.pdf" target={"_blank"}>
            PRZETWARZANIE DANYCH KONTAKTOWYCH NA PODSTAWIE ZGODY
          </a>
        </li>
        <li>
          <a href="/files/rodo/04-PROGRAM-LOJALNOSCIOWY.pdf" target={"_blank"}>
            KLAUZULA INFORMACYJNA RODO DLA OSÓB BIORĄCYCH UDZIAŁ W PROGRAMIE LOJALNOŚCIOWYM
          </a>
        </li>
      </ul>
      <h3 id="ogolna-klauzula" className="rodo-subheading">
        OGÓLNA KLAUZULA INFORMACYJNA RODO
      </h3>
      <p>
        Zgodnie z Art. 13 i 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
        sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
        takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) - RODO, informuje się,
        że:
      </p>
      <ul>
        <li>
          Administratorem Państwa danych osobowych jest Przedsiębiorstwo Handlowe „FAMIX” S i G Tarnowscy Spółka Jawna z
          siedzibą w Rzeszowie przy ul. Trembeckiego 11, e-mail:{" "}
          <a href="mailto:rodo@famix.com.pl">rodo@famix.com.pl</a>, tel: 17 860-06-14.
        </li>
        <li>
          Dane osobowe będą lub mogą być przetwarzane tylko i wyłączenie na zasadach określonych w Art. 6 z
          uwzględnieniem Art. 9 unijnego Rozporządzenia RODO i w zakresie niezbędnym do realizacji określonych celów
          związanych z prowadzoną działalnością gospodarczą przez Administratora oraz spełniania obowiązków wynikających
          z przepisów prawa. Administrator przetwarza lub może przetwarzać dane osobowe na podstawie prawa - w celu
          wypełniania ciążących na nim obowiązków prawnych (Art. 6 ust.1 lit. c RODO), w celu realizacji umów (Art. 6
          ust.1 lit. b RODO), w celu ochrony i dochodzenia przysługujących nam roszczeń oraz realizacji prawnie
          uzasadnionych interesów (Art. 6 ust.1 lit. f RODO) lub na podstawie wyrażonej zgody na przetwarzanie (Art. 6
          ust.1 lit. a RODO).
        </li>
        <li>
          Dane osobowe pozyskane przez Administratora w inny sposób niż od osób, których dane dotyczą, pochodzą lub mogą
          pochodzić ze źródeł publicznie dostępnych (np. internet) oraz od innych podmiotów, które przekazały dane w
          związku ze współpracą w ramach prowadzonej działalności gospodarczej przez Administratora.
        </li>
        <li>
          Odbiorcami danych osobowych są, lub mogą być podmioty uprawnione na podstawie przepisów prawa lub odrębnych
          umów, a w szczególności upoważnieni pracownicy Administratora, podmioty którym należy udostępnić dane w celu
          wykonania obowiązku prawnego, a także podmioty którym dane zostaną powierzone do realizacji celów
          przetwarzania oraz podmioty na podstawie wyrażonej zgody.
        </li>
        <li>
          Generalnie nie przewiduje się przekazywania danych osobowych do państwa trzeciego lub organizacji
          międzynarodowej (podmioty mający siedzibę poza obszarem Europejskiego Obszaru Gospodarczego). W przypadku, gdy
          zaistnieje taka konieczność, to Administrator poinformuje o tym osoby, których dane dotyczą.
        </li>
        <li>
          Dane osobowe będą przetwarzane przez czas niezbędny do realizacji celów przetwarzania. W przypadku zakończenia
          realizacji celu przetwarzania, dane mogą być dalej przetwarzane jeżeli istnieje obowiązek ich archiwizacji z
          mocy prawa, lecz nie dłużej niż przez wymagany prawem okres. Jeżeli dane są przetwarzane na podstawie
          udzielonej zgody, to ich dalsze przetwarzanie zakończy się w momencie pisemnego cofnięcia zgody, bez wpływu na
          zgodność z prawem przetwarzania na podstawie zgody przed jej cofnięciem.
        </li>
        <li>
          Dane osobowe nie będą nie będą podlegały zautomatyzowanym procesom podejmowania decyzji przez Administratora w
          tym profilowaniu.{" "}
        </li>
        <li>
          <Typography.Text>Prawa osób których dane dotyczą:</Typography.Text>
          <ul>
            <li>prawo dostępu do przetwarzanych danych,</li>
            <li>prawo do sprostowania danych,</li>
            <li>
              prawo do usunięcia danych lub ograniczenia ich przetwarzania z zastrzeżeniem sytuacji, gdy ich
              przetwarzanie nie następuje w celu wywiązania się z obowiązku wynikającego z przepisu prawa,
            </li>
            <li>prawo do wniesienia sprzeciwu wobec przetwarzania danych,</li>
            <li>prawo do przenoszenia danych,</li>
            <li>prawo do wniesienia skargi do Urzędu Ochrony Danych Osobowych,</li>
          </ul>
        </li>
        <li>
          W sytuacji, gdy przetwarzanie danych osobowych odbywa się na podstawie wyrażonej zgody osoby, której dane
          dotyczą, podanie danych osobowych Administratorowi ma charakter dobrowolny. Podanie danych osobowych jest
          wymogiem koniecznym, w sytuacji gdy przesłankę przetwarzania danych osobowych stanowi przepis prawa lub jest
          niezbędne dla zawarcia z Państwem umowy.
        </li>
      </ul>
      <span className="rodo-page-sign">Administrator Danych Osobowych</span>
    </section>
  );
};
