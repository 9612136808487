interface UESubsidesProps {}

export const UESubsides: React.FC<UESubsidesProps> = () => {
  return (
    <article className="footer-subsides">
      <div className="footer-subsides-img-wrapper">
        <img src={"/templates/landing/UE.png"} alt="Fundusze Europejskie" />
      </div>
      <div className="footer-subsides-content">
        <p>
          Przedsiębiorstwo Handlowe „FAMIX” S. i G. Tarnowscy s.j. realizuje projekt dofinansowany z Funduszy
          Europejskich „Opracowanie strategii wzorniczej dla firmy FAMIX”
        </p>
        <p>
          Cel projektu: uzyskanie, w wyniku przeprowadzonego audytu, strategii wzorniczej, która pozwoli na wzmocnienie
          konkurencyjności firmy.
        </p>
        <p>
          Planowane efekty: zwiększenie potencjału naszej firmy poprzez skuteczne i umiejętne zarządzanie wzornictwem.
          Poprzez wykorzystanie tego narzędzia chcemy wyróżnić się na rynku oraz sprawić, że nasza firma będzie
          identyfikowalna jako nowoczesny podmiot stosujący wysokie standardy obsługi klienta.
        </p>
        <div>
          <p>
            Całkowita wartość projektu: <strong>91 635,00 zł</strong>
          </p>
          <p>
            Wysokość dofinansowania: <strong>63 325,00 zł</strong>
          </p>
        </div>
      </div>
    </article>
  );
};
