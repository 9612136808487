import { HashLink } from "react-router-hash-link";
import { scrollWithOffset } from "../../../utils/scroll";

interface ClientProps {}

const Client: React.FC<ClientProps> = () => {
  return (
    <ul className="footer-collapse-list">
      <li className="collapse-list-el">
        <HashLink smooth to={`/kontakt/rodo#rodo`} scroll={(el) => scrollWithOffset(-600)(el)}>
          Polityka prywatności
        </HashLink>
      </li>
      <li className="collapse-list-el">
        <HashLink smooth to={`/kontakt#kontakt`} scroll={(el) => scrollWithOffset(-600)(el)}>
          Kontakt
        </HashLink>
      </li>
      <li className="collapse-list-el">
        <HashLink smooth to={`/o-nas#informacje-o-famixie`} scroll={(el) => scrollWithOffset(-600)(el)}>
          O nas
        </HashLink>
      </li>
      <li className="collapse-list-el">
        <HashLink smooth to={`/ehurt#ehurt`} scroll={(el) => scrollWithOffset(-600)(el)}>
          E-hurt
        </HashLink>
      </li>
      <li className="collapse-list-el">
        <HashLink smooth to={`/e-katalog#ecatalog`} scroll={(el) => scrollWithOffset(-600)(el)}>
          E-katalog
        </HashLink>
      </li>
      <li className="collapse-list-el">
        <HashLink smooth to={`/aktualnosci#aktualnosci`} scroll={(el) => scrollWithOffset(-600)(el)}>
          Aktualności
        </HashLink>
      </li>
      <li className="collapse-list-el">
        <HashLink smooth to={`/oferta#oferta-handlowa`} scroll={(el) => scrollWithOffset(-600)(el)}>
          Oferta
        </HashLink>
      </li>
    </ul>
  );
};
export default Client;
