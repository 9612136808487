import { Typography } from "antd";
import { Link, Navigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useAuth } from "../../../providers/auth";
import { scrollWithNavOffset } from "../../../utils/scroll";

import LoginForm from "../components/LoginForm";
interface LoginProps {}
export const Login: React.FC<LoginProps> = () => {
  const { currentUser } = useAuth();
  if (currentUser) return <Navigate to="/admin/panel" />;
  return (
    <section className="auth-layout">
      <div>
        <Typography.Title level={1} style={{ fontSize: "30px", marginBottom: "24px" }}>
          Panel Admina
        </Typography.Title>
        <LoginForm />
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "12px" }}>
          <Link to="/">Strona główna</Link>
          <HashLink smooth to={"/aktualnosci"} scroll={(el) => scrollWithNavOffset(el)}>
            Aktualności
          </HashLink>
        </div>
      </div>
    </section>
  );
};
