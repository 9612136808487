import { useMutation } from "react-query";
import { MutationConfig, queryClient } from "../../../lib/react-query";
import { News } from "../types";
import api from "../../../lib/withAxios";

export type CreateNewsDTO = {
  data: Omit<News, "id">;
};

export const createNews = ({ data }: CreateNewsDTO): Promise<News> => {
  return api.post(`/strona/addGazetka`, data).then((res) => res.data);
};

type UseCreateNewsOptions = {
  config?: MutationConfig<typeof createNews>;
};

export const useCreateNews = ({ config }: UseCreateNewsOptions = {}) => {
  return useMutation(createNews, {
    onSuccess: () => {
      queryClient.invalidateQueries("news");
    },
    ...config,
  });
};
