import React, { createContext, useContext, useState } from "react";
import { message } from "antd";
import { queryClient } from "../lib/react-query";
import { useNavigate } from "react-router-dom";

import useSessionStoragae from "../hooks/useSessionStorage";
import { User } from "../types";
import { CenteredSpinner } from "../components";
interface AuthContextTypes {
  loading: boolean;
  currentUser: any;
  handleLogout: () => void;
  handleUnauthorized: () => void;
  setCurrentUser: any;
}

const AuthContext = createContext<AuthContextTypes>(null!);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useSessionStoragae<User | null>("FAMIX_USER_AUTH", null);

  const handleClearAuth = () => {
    setCurrentUser(null);
  };

  const handleLogout = async () => {
    try {
      handleClearAuth();
      navigate("/admin");
      queryClient.clear();
      message.success("Zostałeś wylogowany");
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnauthorized = async () => {
    try {
      handleClearAuth();
      navigate("/admin");
      queryClient.clear();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider value={{ loading, currentUser, handleLogout, handleUnauthorized, setCurrentUser }}>
      {loading ? <CenteredSpinner /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
