import "./style.less";
import { ReactComponent as Moon } from "../../media/common/moon.svg";
import { useEffect, useState } from "react";
interface AccessibilityProps {}

export const Accessibility: React.FC<AccessibilityProps> = () => {
  const [contrast, setContrast] = useState(false);
  useEffect(() => {
    if (contrast) {
      document.body.classList.add("accessibility-contrast");
    } else {
      document.body.classList.remove("accessibility-contrast");
    }
  }, [contrast]);
  return (
    <div className="accessibility-wrapper">
      <button
        id="large"
        aria-label="duża wielkość czcionki"
        className="xlarge"
        onClick={() => {
          document.body.classList.remove("accessibility-large");
          document.body.classList.add("accessibility-xlarge");
        }}
      >
        a
      </button>
      <button
        id="medium"
        aria-label="średnia wielkość czcionki"
        className="large"
        onClick={() => {
          document.body.classList.add("accessibility-large");
          document.body.classList.remove("accessibility-xlarge");
        }}
      >
        a
      </button>
      <button
        id="normal"
        aria-label="normalna wielkość czcionki"
        className="normal"
        onClick={() => {
          document.body.classList.remove("accessibility-large");
          document.body.classList.remove("accessibility-xlarge");
        }}
      >
        a
      </button>
      <button
        aria-label="Zmień kontrast"
        id="contrast"
        onClick={() => setContrast(!contrast)}
        style={{ display: "flex" }}
      >
        <Moon />
      </button>
    </div>
  );
};
