import "./style.less";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
interface NotFoundProps {}

export const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <div className="not-found-wrapper">
      <div className="not-found-bgc">
        <img src={"/templates/landing/video-frame.jpg"} alt="ciężarówka, dowóz" />
      </div>
      <header className="not-found-header">
        <Typography.Title className="not-found-title">404</Typography.Title>
      </header>
      <div className="not-found-content">
        <Typography.Title level={4} className="not-found-subtitle">
          Strona, której szukasz nie istnieje lub nie może zostać znaleziona.
        </Typography.Title>
        <Button type="primary">
          <Link to="/">Wróć na główną</Link>
        </Button>
        <span style={{ margin: "24px 0" }}>lub sprawdź...</span>
        <div className="not-found-link-wrapper">
          <Button>
            <Link to="/oferta">Oferta</Link>
          </Button>
          <Button>
            <Link to="/o-nas">O nas</Link>
          </Button>
          <Button>
            <Link to="/kontakt">Kontakt</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};
