import "./style.less";
import { Spin } from "antd";

interface CenteredSpinnerProps {}

export const CenteredSpinner: React.FC<CenteredSpinnerProps> = () => {
  return (
    <div className="centered-spinner-wrapper">
      <Spin />
    </div>
  );
};
