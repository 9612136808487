import "./style.less";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Grid } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LocalizationSelect } from "../..";
import { Navigation } from "./Nav";
import { Accessibility } from "../../Accessibility";
import useScrollBlock from "../../../hooks/useScrollBlock";
const { useBreakpoint } = Grid;
interface NavProps {}
export const Nav: React.FC<NavProps> = () => {
  const breakpoints = useBreakpoint();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClose = () => {
    setIsMenuOpen(false);
  };
  useEffect(() => {
    if (isMenuOpen) return blockScroll();
    allowScroll();
  }, [isMenuOpen]);
  useEffect(() => {
    if (breakpoints.lg) {
      setIsMenuOpen(false);
    }
  }, [breakpoints]);
  return (
    <header className="public-layout-nav" id="public-layout-nav">
      <div className="public-layout-nav-site-wrapper">
        <div className="public-layout-nav-logo-wrapper">
          <Link to="/" aria-label="home page">
            <img src={"/templates/landing/logo.png"} alt="famix logo" />
          </Link>
        </div>
        <Navigation isOpen={isMenuOpen} handleClose={handleClose} />
        {breakpoints.lg ? (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Accessibility />
            <LocalizationSelect />
          </div>
        ) : (
          <Button
            type="text"
            icon={isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
            className="public-layout-nav-toggle"
            onClick={handleToggleMenu}
          />
        )}
      </div>
    </header>
  );
};
