import { useMutation } from "react-query";
import { MutationConfig, queryClient } from "../../../lib/react-query";

import api from "../../../lib/withAxios";

export type RemoveNewsDTO = {
  id: number;
};

export const removeNews = ({ id }: RemoveNewsDTO): Promise<any> => {
  return api.delete(`/strona/delGazetka/${id}`).then((res) => res.data);
};

type UseRemoveNewsOptions = {
  config?: MutationConfig<typeof removeNews>;
};

export const useRemoveNews = ({ config }: UseRemoveNewsOptions = {}) => {
  return useMutation(removeNews, {
    onSuccess: () => {
      queryClient.invalidateQueries("news");
    },
    ...config,
  });
};
