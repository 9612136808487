import "./style.less";
import { useState } from "react";
import { Banner } from "./Banner";
interface LandingPageProps {}

export const LandingPage: React.FC<LandingPageProps> = () => {
  const [isVideoLoaded, setIsLoaded] = useState(false);

  return (
    <section className="landing-page" aria-label="strona główna">
      <video
        className="landing-page-video"
        id="landing-page-video"
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
        autoPlay
        loop
        muted
        onLoadedData={() => setIsLoaded(true)}
      >
        <source src={"/templates/landing/video.webm"} type="video/webm" />
      </video>
      <img
        src={"/templates/landing/video-frame.jpg"}
        alt="video frame"
        style={{ opacity: isVideoLoaded ? 0 : 1 }}
        className="landing-page-video-frame"
      />
      <Banner />
    </section>
  );
};
