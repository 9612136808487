import { useRoutes } from "react-router-dom";
import { NotFound } from "../features/common";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

export const AppRoutes = () => {
  const commonRoutes = [{ path: "*", element: <NotFound /> }];

  const element = useRoutes([...protectedRoutes, ...publicRoutes, ...commonRoutes]);

  return <>{element}</>;
};
