import { Spin } from "antd";
import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Login } from "../features/admin";
import { WithAxios } from "../lib/withAxios";
import AuthProvider from "../providers/auth";

import { lazyImport } from "../utils/lazyImport";
import ProtectedAdminRoute from "./ProtectedAdminRoute";

const { Dashboard } = lazyImport(() => import("../features/admin"), "Dashboard");

const App = () => {
  return (
    <AuthProvider>
      <WithAxios>
        <Suspense
          fallback={
            <div>
              <Spin />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </WithAxios>
    </AuthProvider>
  );
};

export const protectedRoutes = [
  {
    path: "/admin/*",
    element: <App />,
    children: [
      { path: "", element: <Login /> },
      {
        path: "panel",
        element: (
          <ProtectedAdminRoute>
            <Dashboard />
          </ProtectedAdminRoute>
        ),
      },

      { path: "*", element: <Navigate to="" /> },
    ],
  },
];
