export const navData = [
  {
    id: 0,
    name: "oferta",
    href: "/oferta",

    children: [
      {
        id: 1,
        name: "Oferta handlowa",
        href: "/oferta#oferta-handlowa",
        icon: null,
        children: null,
      },

      {
        id: 2,
        name: "Oferta okolicznościowa",
        href: "/oferta#oferta-okolicznosciowa",
        icon: null,
        children: null,
      },
      {
        id: 3,
        name: "Oferta paczkowa",
        href: "/oferta#oferta-paczkowa",
        icon: null,
        children: null,
      },
    ],
  },
  {
    id: 15,
    name: "Aktualności",
    href: "/aktualnosci#aktualnosci",
    icon: null,
    children: null,
  },
  {
    id: 23,
    name: "E-hurt",
    href: "/ehurt#ehurt",
    icon: null,
    children: null,
  },
  {
    id: 24,
    name: "E-katalog",
    href: "/e-katalog#ecatalog",
    icon: null,
    children: null,
  },
  {
    id: 12,
    name: "O nas",
    href: "/o-nas",

    children: [
      {
        id: 13,
        name: "Informacje o Famixie",
        href: "/o-nas#informacje-o-famixie",
        icon: null,
        children: null,
      },
      {
        id: 13,
        name: "Dlaczego z nami",
        href: "/o-nas#dlaczego-z-nami",
        icon: null,
        children: null,
      },
      {
        id: 16,
        name: "Grupa MPT",
        href: "/o-nas#grupa-mpt",
        icon: null,
        children: null,
      },

      {
        id: 14,
        name: "Dane podstawowe firmy",
        href: "/o-nas#dane-podstawowe-firmy",
        icon: null,
        children: null,
      },
    ],
  },
  {
    id: 18,
    name: "Kontakt",
    href: "/kontakt",
    // icon: <Projekt />,
    children: [
      {
        id: 20,
        name: "Informacje ogólne",
        href: "/kontakt#kontakt",
        icon: null,
        children: null,
      },
      {
        id: 20,
        name: "Przedstawiciele Famix",
        href: "/kontakt#przedstawiciele",
        icon: null,
        children: null,
      },
      {
        id: 21,
        name: "Nowy klient",
        href: "/kontakt#nowy-klient",
        icon: null,
        children: null,
      },
      {
        id: 22,
        name: "RODO",
        href: "/kontakt/rodo#rodo",
        icon: null,
        children: null,
      },
    ],
  },
  {
    id: 25,
    name: "Projekt",
    href: "/projekt#projekt",
    icon: null,
    children: null,
  },
];
