import "./style.less";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import * as Yup from "yup";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Form, Input, Button, Typography, Select, DatePicker, message, Radio } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { useCreateNews } from "../../api/addNews";
import moment, { Moment } from "moment";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { News } from "../../types";
import { useUpdateNews } from "../../api/updateNews";

const NewsSchema = Yup.object().shape({
  typ: Yup.number().required("Opcja wymagana!"),
  nazwa: Yup.string().max(150, "Maksimum 150 znaków!").required("Pole obowiązkowe"),
  oddzial: Yup.string().max(300, "Maksimum 300 znaków!").required("Pole obowiązkowe"),
  imgUrl: Yup.string().required("Pole obowiązkowe"),
  dataWyswietlaniaOd: Yup.date().required("Pole obowiązkowe").typeError("Wprowadź datę"),
  offerDate: Yup.array().required("Pole obowiązkowe").typeError("Wprowadź datę"),
  opisKrotki: Yup.string().max(400, "Maksimum 400 znaków!"),
});

interface FormValues {
  typ: number;
  nazwa: string;
  oddzial: string;
  imgUrl: string;
  dataWyswietlaniaOd: Moment;
  offerDate: [Moment, Moment];
  opisKrotki: string;
}
export interface NewsWithId extends FormValues {
  id: string;
  catId: string;
  draft: any;
  createdAt: string;
}

interface NewsFormProps {
  edited: News;
  handleClose: () => void;
}

const createContentBlock = (html: string) => {
  try {
    if (!html) return;

    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return;
  } catch (err) {
    return;
  }
};
const convertDraftToHTML = (editorState: EditorState | undefined) => {
  try {
    if (!editorState) return "<p></p>";
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (!html) return "<p></p>";
    return html;
  } catch (err) {
    return "<p></p>";
  }
};
export const NewsForm: React.FC<NewsFormProps> = ({ edited, handleClose }) => {
  const createNews = useCreateNews();
  const updateNews = useUpdateNews();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(
    edited && edited.opisDlugi ? createContentBlock(edited.opisDlugi) : EditorState.createEmpty()
  );

  const initialValues: FormValues = {
    typ: edited ? edited.typ : 1,
    nazwa: edited ? edited.nazwa : "",
    oddzial: edited ? edited.oddzial : "RZ",
    imgUrl: edited ? edited.imgUrl : "",
    dataWyswietlaniaOd: edited ? moment(edited.dataWyswietlaniaOd) : moment(new Date()),
    offerDate: edited
      ? [moment(edited.startGazetki), moment(edited.koniecGazetki)]
      : [moment(new Date()), moment(new Date())],
    opisKrotki: edited ? edited.opisKrotki : "",
  };

  const handleChangeEditor = (state: any) => {
    setEditorState(state);
  };

  const handleSubmit = async (values: FormValues, actions: any) => {
    setLoading(true);

    try {
      let id =
        values.nazwa
          .trim()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[&/\\#,+()$~%.'":*?<>{}!]/g, "")
          .replaceAll(" ", "_") + `_${values.oddzial}`;

      const newNews = {
        nazwa: values.nazwa,
        imgUrl: values.imgUrl,
        sciezka: id,
        opisKrotki: values.opisKrotki,
        typ: values.typ,
        oddzial: values.oddzial,
        opisDlugi: convertDraftToHTML(editorState),
        dataWyswietlaniaOd: values.dataWyswietlaniaOd.format("YYYY-MM-DD HH:mm:ss"),
        startGazetki: values.offerDate[0].format("YYYY-MM-DD HH:mm:ss"),
        koniecGazetki: values.offerDate[1].format("YYYY-MM-DD HH:mm:ss"),
        dataDodania: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };

      if (edited && edited.id) {
        await updateNews.mutate({ data: { ...newNews, id: edited.id } });
        message.success(
          `${values.typ === 1 ? "Gazetka" : "News"} ${values.typ === 1 ? "zaaktualizowana" : "zaaktualizowany"}`
        );
        handleClose();
      } else {
        await createNews.mutate({ data: newNews });
        message.success(`${values.typ === 1 ? "Gazetka" : "News"} ${values.typ === 1 ? "dodana" : "dodany"}`);
      }

      actions.resetForm();
      setEditorState(EditorState.createEmpty());
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      message.error(`Wystąpił błąd podczas dodawania ${values.typ === 1 ? "gaetki" : "newsa"}`);
      console.log(err);
    }
  };

  return (
    <Formik validationSchema={NewsSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, errors, touched, setFieldValue }) => (
        <FormikForm className="news-form">
          {edited ? null : (
            <Radio.Group
              style={{ marginBottom: "12px" }}
              value={values.typ}
              onChange={(e) => setFieldValue("typ", e.target.value)}
            >
              <Radio value={1}>Gazetka</Radio>
              <Radio value={0}>News</Radio>
            </Radio.Group>
          )}
          <div className="news-form-half">
            <Form.Item label={`Nazwa ${values.typ === 1 ? "gazetki" : "newsa"}`}>
              <Input
                max={150}
                placeholder="Nazwa"
                name="nazwa"
                value={values.nazwa}
                onChange={handleChange}
                maxLength={100}
              />
              {errors.nazwa && touched.nazwa ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.nazwa}
                </Typography.Text>
              ) : null}
            </Form.Item>
            <Form.Item label="Oddział">
              <Select
                placeholder="Oddział"
                value={values.oddzial}
                onChange={(value) => setFieldValue("oddzial", value)}
              >
                <Select.Option value="RZ">Rzeszów</Select.Option>
                <Select.Option value="KR">Kraków</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <Form.Item label={values.typ === 1 ? "Link do gazetki" : "Link do zdjęcia"}>
            <Input
              placeholder={`${
                values.typ === 1
                  ? "http://www.famix.com.pl/pliki/galeria/255/;44;"
                  : "https://www.famix.com.pl/pliki/galeria/251/01strona.jpg"
              }`}
              name="imgUrl"
              value={values.imgUrl}
              onChange={handleChange}
              maxLength={100}
            />
            {errors.imgUrl && touched.imgUrl ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.imgUrl}
              </Typography.Text>
            ) : null}
          </Form.Item>

          <Form.Item label="Data publikacji">
            <DatePicker
              // disabledDate={disabledDate}
              name="dataWyswietlaniaOd"
              value={values.dataWyswietlaniaOd}
              onChange={(value) => setFieldValue("dataWyswietlaniaOd", value)}
            />
            {errors.dataWyswietlaniaOd && touched.dataWyswietlaniaOd ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.dataWyswietlaniaOd}
              </Typography.Text>
            ) : null}
          </Form.Item>
          {values.typ === 1 ? (
            <Form.Item label="Data ważności oferty">
              <DatePicker.RangePicker
                // disabledDate={disabledDate}
                name="offerDate"
                value={values.offerDate}
                onChange={(value) => setFieldValue("offerDate", value)}
              />
              {errors.offerDate && touched.offerDate ? (
                <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                  {errors.offerDate}
                </Typography.Text>
              ) : null}
            </Form.Item>
          ) : null}

          <Form.Item label="Opis krótki">
            <Input.TextArea
              name="opisKrotki"
              placeholder="Opis"
              maxLength={400}
              value={values.opisKrotki}
              onChange={handleChange}
              style={{ marginBottom: "12px" }}
              rows={5}
            />
            {errors.opisKrotki && touched.opisKrotki ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.opisKrotki}
              </Typography.Text>
            ) : null}
          </Form.Item>
          <Form.Item label="Opis długi">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-body"
              onEditorStateChange={handleChangeEditor}
              toolbar={{
                options: ["inline", "link", "list", "fontSize", "textAlign", "image"], // This is where you can specify what options you need in
                //the toolbar and appears in the same order as specified
                inline: {
                  options: ["bold", "italic", "underline"], // this can be specified as well, toolbar wont have
                  //strikethrough, 'monospace', 'superscript', 'subscript'
                },
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button style={{ maxWidth: "240px" }} type="primary" htmlType="submit" disabled={loading} loading={loading}>
              {edited ? "Zapisz" : "Dodaj"}
            </Button>
          </Form.Item>
        </FormikForm>
      )}
    </Formik>
  );
};
