import "./style.less";
import { Select, Grid } from "antd";
import { useGeo } from "../../providers/geolocation";
const { useBreakpoint } = Grid;
interface LocalizationSelectProps {}
const DESKTOP_ALIGN = {
  points: ["tl", "bl"],
  offset: [0, 5],
  overflow: { adjustX: false, adjustY: false },
};
const MOBILE_ALIGN = {
  points: ["bl", "bl"],
  offset: [0, -35],
  overflow: { adjustX: false, adjustY: false },
};
export const LocalizationSelect: React.FC<LocalizationSelectProps> = () => {
  const breakpoints = useBreakpoint();
  const { voivodeship, handleChange } = useGeo();

  return (
    <Select
      value={voivodeship ? voivodeship : "Podkarpackie/Centrala"}
      style={{ width: 140 }}
      onChange={handleChange}
      className="localization-select"
      aria-label="Wybierz lokalizację"
      aria-labelledby="podkarpacie"
      dropdownAlign={breakpoints.lg ? { ...DESKTOP_ALIGN } : { ...MOBILE_ALIGN }}
      //@ts-ignore
      getPopupContainer={() => document.getElementById("public-layout-nav")}
    >
      <Select.Option
        id="podkarpacie"
        aria-labelledby="malopolska"
        aria-label="Podkarpacie"
        value="Podkarpackie/Centrala"
      >
        Podkarpackie/Centrala
      </Select.Option>
      <Select.Option id="malopolska" aria-label="Małopolska" value="Małopolskie">
        Małopolskie
      </Select.Option>
    </Select>
  );
};
