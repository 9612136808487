import { SubpageLayout } from "../../../components";
import { RodoContent } from "../components";

interface RodoProps {}

export const Rodo: React.FC<RodoProps> = () => {
  return (
    <SubpageLayout
      subpage="rodo"
      banner={{
        img: "/templates/rodo/top-background.jpg",
        heading: "RODO",
        subheading: "Ochrona danych osobowych RODO",
      }}
    >
      <RodoContent />
    </SubpageLayout>
  );
};
