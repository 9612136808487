import "./style.less";
import { Tabs } from "antd";
import { useGeo } from "../../../../providers/geolocation";

import { useHtml } from "../../../../providers/html";
interface RepresentativesTabsProps {}

export const RepresentativesTabs: React.FC<RepresentativesTabsProps> = () => {
  const { voivodeship } = useGeo();
  const { contactRepres } = useHtml();
  if (!contactRepres) return null;
  return (
    <section className="representatives" id="przedstawiciele">
      <h2>Przedstawiciele Famix</h2>
      <Tabs defaultActiveKey={voivodeship === "Podkarpackie/Centrala" ? "0" : "1"} className="representatives-tabs">
        {contactRepres.map((branch) => (
          <Tabs.TabPane tab={branch.name} key={branch.id}>
            <div className="representatives-wrapper">
              {branch.people.map((people) => (
                <article className="representatives-single" key={people.id}>
                  <h3>{people.name}</h3>
                  <ul>
                    {people.ext.map((list, index) => (
                      <li key={index}>{list}</li>
                    ))}
                  </ul>
                </article>
              ))}
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </section>
  );
};
