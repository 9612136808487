import { Drawer } from "antd";
import { NewsForm } from "..";

interface AddNewDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  edited: any;
}

export const AddNewDrawer: React.FC<AddNewDrawerProps> = ({ isOpen, handleClose, edited }) => {
  return (
    <Drawer
      title="Dodaj nową gazetkę/news"
      width={520}
      onClose={handleClose}
      visible={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      maskClosable={false}
    >
      <NewsForm edited={edited} handleClose={handleClose} />
    </Drawer>
  );
};
