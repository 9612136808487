import "./style.less";
import { Nav } from "./Nav";
interface PublicLayoutProps {}

export const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  return (
    <div id="public-layout" className="public-layout" style={{ backgroundColor: "#fff" }}>
      <Nav />
      <main>{children}</main>
    </div>
  );
};
