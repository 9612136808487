interface CopyrightProps {}

export const Copyright: React.FC<CopyrightProps> = () => {
  return (
    <div className="footer-copyright">
      <p>v{process.env.REACT_APP_VERSION} © 2022 Famix ‐ Wszelkie prawa zastrzeżone - <span className={'realizacja'}>Realizacja: <a href="http://topsa.com.pl/" target="_blank">TOP S.A.</a>  - oprogramowanie: <a href={"https://topsa.com.pl/produkty/top-ehurt"} target="_blank"> Top eHurt</a></span>
      </p>
    </div>
  );
};
