import "./style.less";
import { Divider, Grid } from "antd";
import { UESubsides } from "./UESubsides";
import { Copyright } from "./Copyright";
import { GridView } from "./GridView";
import Collapse from "./Collapse";
const { useBreakpoint } = Grid;
interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const breakpoint = useBreakpoint();

  return (
    <footer className="footer">
      {breakpoint.lg ? <GridView /> : <Collapse />}
      {breakpoint.lg ? <Divider /> : null}
      <UESubsides />
      <Divider />
      <Copyright />
    </footer>
  );
};
