import { Collapse as AntdCollapse } from "antd";
import Client from "./Client";

interface CollapseProps {}

const Collapse: React.FC<CollapseProps> = () => {
  return (
    <AntdCollapse bordered={false} className="footer-collapse" defaultActiveKey={[1]}>
      <AntdCollapse.Panel header="Obsługa klienta" key="1">
        <Client />
      </AntdCollapse.Panel>
      <AntdCollapse.Panel header="Informacje ogólne" key="2">
        <ul className="footer-collapse-list">
          <li className="collapse-list-el">
            <p>Przedsiębiorstwo Handlowe "Famix", S. i G. Tarnowscy s.j.</p>
          </li>
          <li className="collapse-list-el">
            <p>35-234 Rzeszów, ul. Trembeckiego 11,</p>
          </li>
          <li className="collapse-list-el">
            <p>Nip: 813-10-27-676</p>
          </li>
          <li className="collapse-list-el">
            <p>Zarejestrowany pod numerem KRS: 0000008908 w Sądzie Rejonowym w Rzeszowie</p>
          </li>
        </ul>
      </AntdCollapse.Panel>
      <AntdCollapse.Panel header="Biuro w Rzeszowie" key="3">
        <ul className="footer-collapse-list">
          <li className="collapse-list-el">
            <p>Adres: 35-234 Rzeszów, ul. Trembeckiego 11,</p>
          </li>
          <li className="collapse-list-el">
            <p>GLN: 5903111546007</p>
          </li>
          <li className="collapse-list-el collapse-list-el-separate">
            <p>Godziny otwarcia: </p>
            <ul className="footer-collapse-list">
              <li>
                <p>
                  pon-pt: <strong>7:00 - 16:00</strong>
                </p>
              </li>
            </ul>
          </li>
          <li className="collapse-list-el collapse-list-el-separate">
            <p>Sekretariat: </p>
            <ul className="footer-collapse-list">
              <li>
                <p>
                  <strong>(17) 860-06-13</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>(17) 860-06-14</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>(17) 853-28-70</strong>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </AntdCollapse.Panel>
      <AntdCollapse.Panel header="Magazyn w Rzeszowie" key="4">
        <ul className="footer-collapse-list">
          <li className="collapse-list-el">
            <p>Adres: 35-213 Rzeszów, ul. Ludwika Chmury 3,</p>
          </li>
          <li className="collapse-list-el">
            <p>GLN: 5903111546038</p>
          </li>
          <li className="collapse-list-el collapse-list-el-separate">
            <p>Godziny otwarcia: </p>
            <ul className="footer-collapse-list">
              <li>
                <p>
                  pon-pt: <strong>7:00 - 18:00</strong>{" "}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </AntdCollapse.Panel>
      <AntdCollapse.Panel header="Oddział w Krakowie" key="5">
        <ul className="footer-collapse-list">
          <li className="collapse-list-el">
            <p>Adres: 32-002 Kokotów, Kokotów 812E</p>
          </li>
          <li className="collapse-list-el">
            <p>GLN: 5903111546021</p>
          </li>
          <li className="collapse-list-el collapse-list-el-separate">
            <p>Godziny otwarcia: </p>
            <ul className="footer-collapse-list">
              <li>
                <p>
                  pon-pt: <strong>7:00 - 18:00</strong>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </AntdCollapse.Panel>
    </AntdCollapse>
  );
};

export default Collapse;
