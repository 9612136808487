export const slides = [
  {
    id: 0,
    name: "Szeroki asortyment towarów",
  },
  {
    id: 1,
    name: "Dogodne terminy płatności",
  },
  {
    id: 2,
    name: "Dostawa towaru do klienta",
  },
  {
    id: 3,
    name: "Dużo produktów w promocji",
  },
  {
    id: 4,
    name: "Profesjonalna obsługa PH",
  },
];
