import { Table, Space, Button, message, Popconfirm } from "antd";
import { useMemo } from "react";
import { useRemoveNews } from "../../api/removeNews";
import { News } from "../../types";
import moment from "moment";

interface ArticlesTableProps {
  handleOpen: (tmp: any) => void;
  news: News[];
}

export const ArticlesTable: React.FC<ArticlesTableProps> = ({ handleOpen, news }) => {
  const removeNews = useRemoveNews();
  const handleRemoveItem = async (id: number) => {
    try {
      await removeNews.mutate({ id });
      message.success("Gazetka/news usunięta");
    } catch (err) {
      console.log(err);
      message.error("Coś poszło nie tak!");
    }
  };
  const columns = useMemo(
    () => [
      {
        title: "Nazwa",
        dataIndex: "nazwa",
        key: "nazwa",
        sorter: (a: News, b: News) => a.nazwa.length - b.nazwa.length,
      },
      {
        title: "Oddział",
        dataIndex: "oddzial",
        key: "oddzial",
        sorter: (a: News, b: News) => a.oddzial.length - b.oddzial.length,
      },
      {
        title: "Data dodania",
        dataIndex: "dataDodania",
        key: "dataDodania",
        render: (date: string) => moment(date).format("DD-MM-YYYY"),
        sorter: (a: News, b: News) => (a.dataDodania > b.dataDodania ? -1 : 1),
      },
      {
        title: "Data publikacji",
        dataIndex: "dataWyswietlaniaOd",
        key: "dataWyswietlaniaOd",
        render: (date: string) => moment(date).format("DD-MM-YYYY"),
        sorter: (a: News, b: News) => (a.dataWyswietlaniaOd > b.dataWyswietlaniaOd ? -1 : 1),
      },
      {
        title: "Gazetka obowiązuje",
        dataIndex: "startGazetki",
        key: "startGazetki",
        render: (_record: any, data: News) => (
          <span>
            od {moment(data.startGazetki).format("DD-MM-YYYY")} do {moment(data.koniecGazetki).format("DD-MM-YYYY")}
          </span>
        ),
      },

      {
        title: "Akcja",
        key: "action",
        render: (text: any, record: News) => (
          <Space>
            <Button onClick={() => handleOpen(record)}>Edytuj</Button>
            <Popconfirm
              placement="topLeft"
              onConfirm={() => handleRemoveItem(record.id)}
              title={"Jesteś pewien?"}
              okText="Tak"
              cancelText="Nie"
            >
              <Button type="primary" danger>
                Usuń
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    []
  );

  return <Table columns={columns} dataSource={news} />;
};
