import { useMutation } from "react-query";
import { MutationConfig, queryClient } from "../../../lib/react-query";
import { News } from "../types";
import api from "../../../lib/withAxios";

export type UpdateNewsDTO = {
  data: News;
};

export const updateNews = ({ data }: UpdateNewsDTO): Promise<News> => {
  return api.put(`/strona/updGazetka`, data).then((res) => res.data);
};

type UseUpdateNewsOptions = {
  config?: MutationConfig<typeof updateNews>;
};

export const useUpdateNews = ({ config }: UseUpdateNewsOptions = {}) => {
  return useMutation(updateNews, {
    onSuccess: () => {
      queryClient.invalidateQueries("news");
    },
    ...config,
  });
};
