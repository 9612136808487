import { useQuery } from "react-query";
import axios from "axios";
import { QueryConfig } from "../lib/react-query";

export const getDynamicData = (href: string): Promise<any> => {
  return axios.get(`/templates${href}`).then((res) => res.data);
};

type UseDynamicDataOptions = {
  href: string;
  config?: QueryConfig<typeof getDynamicData>;
};

export const useDynamicData = ({ href }: UseDynamicDataOptions) => {
  return useQuery({
    queryKey: [href, href],
    queryFn: () => getDynamicData(href),
  });
};
