import { lazyImport } from "../utils/lazyImport";

import { Landing } from "../features/common";
import { PublicLayout } from "../components";
import { Outlet } from "react-router-dom";
import {Rodo} from "../features/contact/routes/Rodo";

const { Ecatalog } = lazyImport(() => import("../features/offer/routes/Ecatalog"), "Ecatalog");
const { OfferRoutes } = lazyImport(() => import("../features/offer"), "OfferRoutes");
const { AboutRoutes } = lazyImport(() => import("../features/about"), "AboutRoutes");
const { ContactRoutes } = lazyImport(() => import("../features/contact"), "ContactRoutes");
const { ProjektRoutes } = lazyImport(() => import("../features/projekt"), "ProjektRoutes");
const { NewsRoutes } = lazyImport(() => import("../features/news"), "NewsRoutes");
const { EhurtRoutes } = lazyImport(() => import("../features/ehurt"), "EhurtRoutes");

const App = () => {
  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export const publicRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Landing /> },
      { path: "/o-nas/*", element: <AboutRoutes /> },
      { path: "/kontakt/*", element: <ContactRoutes /> },
      { path: "/RODO", element: <Rodo /> },
      { path: "/projekt/*", element: <ProjektRoutes /> },
      { path: "/oferta/*", element: <OfferRoutes /> },
      { path: "/e-katalog", element: <Ecatalog /> },
      { path: "/aktualnosci/*", element: <NewsRoutes /> },
      { path: "/ehurt/*", element: <EhurtRoutes /> },
    ],
  },
];
