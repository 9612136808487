import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/auth";

interface ProtectedAdminRouteProps {}

const ProtectedAdminRoute: React.FC<ProtectedAdminRouteProps> = ({ children }) => {
  const { currentUser } = useAuth();
  if (!currentUser) return <Navigate to="/admin" />;
  return <>{children}</>;
};

export default ProtectedAdminRoute;
