import React, { createContext, useContext } from "react";
import { FullPageLoading } from "../components";
import { ContactTabType, RepresentativeTab } from "../features/contact";
import { useDynamicData } from "../utils/getDynamicData";

interface HtmlContextTypes {
  contactGeneral: any;
  projektGeneral: any;
  contactTabs: ContactTabType[] | null;
  contactRepres: RepresentativeTab[] | null;
  offerCommercial: any;
  offerOccasional: any;
  offerPackage: any;
  aboutFamix: any;
  whyWithUs: any;
  mpt: any;
  mobile: any;
  web: any;
}

const HtmlContext = createContext<HtmlContextTypes>(null!);

export const useHtml = () => {
  return useContext(HtmlContext);
};

const parseHtml = (html: any) => {
  if (!html) return;
  const parser = new DOMParser();
  // Parse the text
  const doc = parser.parseFromString(html, "text/html");
  if (!doc) return;
  //@ts-ignore
  const body = doc.querySelector("body").innerHTML;
  return body;
};
const HtmlProvider = ({ children }: any) => {
  const contactGeneralQuery = useDynamicData({ href: "/contact/general-info.html" });
  const contactTabsQuery = useDynamicData({ href: "/contact/contact-tabs.json" });
  const contactRepresentativesQuery = useDynamicData({ href: "/contact/representatives.json" }); //CONTACT PAGE

  const projektGeneralQuery = useDynamicData({ href: "/projekt/projekt-info.html" });

  const offerCommercialQuery = useDynamicData({ href: "/offer/commercial-offer.html" });
  const offerOccasionalQuery = useDynamicData({ href: "/offer/occasional-offer.html" });
  const offerPackageQuery = useDynamicData({ href: "/offer/package-offer.html" }); // OFFER PAGE

  const aboutFamixQuery = useDynamicData({ href: "/about/about-famix.html" });
  const whyWithUsQuery = useDynamicData({ href: "/about/why-with-us.html" });
  const mptQuery = useDynamicData({ href: "/about/mpt.html" }); // ABOUT US PAGE

  const mobileQuery = useDynamicData({ href: "/ehurt/mobile.html" });
  const webQuery = useDynamicData({ href: "/ehurt/web.html" }); //EHURT

  const isLoading =
    contactTabsQuery.isLoading ||
    contactGeneralQuery.isLoading ||
    contactRepresentativesQuery.isLoading ||
    projektGeneralQuery.isLoading ||
    offerCommercialQuery.isLoading ||
    offerOccasionalQuery.isLoading ||
    offerPackageQuery.isLoading ||
    aboutFamixQuery.isLoading ||
    whyWithUsQuery.isLoading ||
    mptQuery.isLoading ||
    mobileQuery.isLoading ||
    webQuery.isLoading;

  return (
    <HtmlContext.Provider
      value={{
        contactGeneral: parseHtml(contactGeneralQuery.data),
        projektGeneral: parseHtml(projektGeneralQuery.data),
        contactTabs: contactTabsQuery.data,
        contactRepres: contactRepresentativesQuery.data,
        offerCommercial: parseHtml(offerCommercialQuery.data),
        offerOccasional: parseHtml(offerOccasionalQuery.data),
        offerPackage: parseHtml(offerPackageQuery.data),
        aboutFamix: parseHtml(aboutFamixQuery.data),
        whyWithUs: parseHtml(whyWithUsQuery.data),
        mpt: parseHtml(mptQuery.data),
        mobile: parseHtml(mobileQuery.data),
        web: parseHtml(webQuery.data),
      }}
    >
      {isLoading ? <FullPageLoading /> : children}
    </HtmlContext.Provider>
  );
};

export default HtmlProvider;
