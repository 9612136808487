import { Coordinates } from "../types";
export const RZESZOW_COORDS = { latitude: 50.041187, longitude: 21.999121 };
export const OUTSIDE_RZESZOW_AND_KRAKOW = { latitude: 40.591504, longitude: 13.144229 };
export const requestLocation = (setPosition: any) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      },
      () => {
        setPosition(OUTSIDE_RZESZOW_AND_KRAKOW);
      }
    );
  } else setPosition(OUTSIDE_RZESZOW_AND_KRAKOW);
};

export const checkIfInsideCoordinates =
  (coords: { start: Coordinates; end: Coordinates }) => (checked: Coordinates) => {
    const { start, end } = coords;
    return (
      checked.latitude > start.latitude &&
      checked.latitude <= end.latitude &&
      checked.longitude >= start.longitude &&
      checked.longitude <= end.longitude
    );
  };
export const checkIfMazowieckie = checkIfInsideCoordinates({
  start: { latitude: 49.277382, longitude: 19.0763 },
  end: { latitude: 50.491381, longitude: 21.2406 },
});
export const checkIfPodkarpackie = checkIfInsideCoordinates({
  start: { latitude: 49.020645, longitude: 21.2406 },
  end: { latitude: 50.591504, longitude: 23.144229 },
});
export const getVoivodeshipByLocalization = (coords: Coordinates) => {
  if (checkIfPodkarpackie(coords)) return "Podkarpackie/Centrala";
  else if (checkIfMazowieckie(coords)) return "Małopolskie";
  else return undefined;
};
