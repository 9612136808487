import "./style.less";
import { encode as base64_encode } from "base-64";
import { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { Formik, Form as FormikForm } from "formik";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../providers/auth";
import { useNavigate } from "react-router-dom";
import { loginWithUsernameAndPassword } from "../../api/login";

interface LoginFormProps {}

const initialValues: any = {
  username: "",
  password: "",
};

const LoginForm: React.FC<LoginFormProps> = () => {
  const ref = useRef(false);
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: any, actions: any) => {
    setLoading(true);
    try {
      const res = await loginWithUsernameAndPassword(values);

      const token = base64_encode(`${res.username}:${values.password}`);
      res.token = token;
      setLoading(false);
      setCurrentUser(res);
      navigate("/admin/panel");
    } catch (err: any) {
      setLoading(false);

      if (err.response && err.response.data && err.response.data.message) {
        return actions.setFieldError("password", err.response.data.message);
      }
    }
  };
  useEffect(() => {
    return () => {
      ref.current = true;
    };
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, errors, touched }) => (
        <FormikForm className="signin__form">
          <Form.Item label="Email">
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              name="username"
              value={values.username}
              onChange={handleChange}
            />
            {errors.username && touched.username ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.username}
              </Typography.Text>
            ) : null}
          </Form.Item>

          <Form.Item label="Hasło">
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              name="password"
              placeholder="Hasło"
              value={values.password}
              onChange={handleChange}
              style={{ marginBottom: "12px" }}
            />
            {errors.password && touched.password ? (
              <Typography.Text type="danger" style={{ fontSize: "12px" }}>
                {errors.password}
              </Typography.Text>
            ) : null}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" disabled={loading} loading={loading}>
              Zaloguj
            </Button>
          </Form.Item>
        </FormikForm>
      )}
    </Formik>
  );
};

export default LoginForm;
