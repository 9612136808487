import "./style.less";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Tabs } from "antd";
import { useGeo } from "../../../../providers/geolocation";
import { Map, Marker } from "../";
import { useEffect, useState } from "react";
import { useHtml } from "../../../../providers/html";
interface ContactTabsProps {}

export const ContactTabs: React.FC<ContactTabsProps> = () => {
  const { voivodeship } = useGeo();
  const { contactTabs } = useHtml();

  const [activeTab, setActiveTab] = useState(voivodeship === "Podkarpackie/Centrala" ? "0" : "1");
  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = useState(15); // initial zoom

  const onClick = (e: google.maps.MapMouseEvent) => {
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    if (!m) return;
    setZoom(m.getZoom());
  };
  const handleChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };
  useEffect(() => {}, [activeTab]);
  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };
  if (!contactTabs) return null;
  return (
    <>
      <Tabs activeKey={activeTab} onChange={handleChange} className="contact-branches">
        {contactTabs.map((branch) => (
          <Tabs.TabPane tab={branch.name} key={branch.id} className="contact-tabs">
            <div className="contact-branches-wrapper">
              {branch.subBranches.map((subBranch) => (
                <div key={subBranch.name}>
                  {branch.subBranches.length === 1 ? null : (
                    <div className="adress">
                      <h2>{subBranch.name}</h2>
                      <p>{subBranch.adress}</p>
                    </div>
                  )}

                  {subBranch.branches.map((branchPart) => (
                    <article className="contact-branches-branch" key={branchPart.id}>
                      <h3>{branchPart.name}</h3>
                      <div className="contact-branches-branch-wrapper">
                        {branchPart.lists.map((list, index) => (
                          <ul key={index}>
                            {list.map((el) => (
                              <li key={el.id}>
                                {el.type === "email" ? (
                                  <>
                                    email:{" "}
                                    <a href={`mailto:${el.text}`} style={{ textDecoration: "underline" }}>
                                      {el.text}
                                    </a>
                                  </>
                                ) : (
                                  el.text
                                )}
                              </li>
                            ))}
                          </ul>
                        ))}
                      </div>
                    </article>
                  ))}
                  <div className="contact-branches-map-wrapper">
                    <h4>Dojazd do {subBranch.name}</h4>
                    {branch.subBranches.length === 1 ? <p>{subBranch.adress}</p> : null}

                    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY || ""} render={render}>
                      <Map center={subBranch.cords} onClick={onClick} onIdle={onIdle} zoom={zoom}>
                        <Marker position={subBranch.cords} />
                      </Map>
                    </Wrapper>
                  </div>
                </div>
              ))}
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};
