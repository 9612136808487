import { createContext, useContext, useEffect, useState } from "react";
import { requestLocation, getVoivodeshipByLocalization } from "../utils/geolocation";
import { LocalizationModal } from "../components";
import { Coordinates, LocalizationType } from "../types";
import { useLocation } from "react-router-dom";

interface GeolocationContextTypes {
  voivodeship: LocalizationType;
  handleChange: (value: LocalizationType) => void;
}

const GeolocationContext = createContext<GeolocationContextTypes>(null!);

export const useGeo = () => {
  return useContext(GeolocationContext);
};

const GeolocationProvider = ({ children }: any) => {
  const location = useLocation();

  const [voivodeship, setVoivodeship] = useState<LocalizationType>("Podkarpackie/Centrala");

  const handleChange = (value: LocalizationType) => {
    setVoivodeship(value);
  };

  const handleSetVoivodeship = (coords: Coordinates) => {
    setVoivodeship(getVoivodeshipByLocalization(coords));
  };

  useEffect(() => {
    const tmpGeo = new URLSearchParams(location.search).get("voivodeship");
    if (tmpGeo && (tmpGeo === "Małopolskie" || tmpGeo === "Podkarpackie/Centrala")) {
      return handleChange(tmpGeo);
    }

    requestLocation(handleSetVoivodeship);
  }, []);

  return (
    <GeolocationContext.Provider value={{ voivodeship, handleChange }}>
      {voivodeship ? children : <LocalizationModal setLocalization={handleChange} />}
    </GeolocationContext.Provider>
  );
};

export default GeolocationProvider;
