import "./style.less";
import { Children, cloneElement, isValidElement, useEffect, useRef, useState } from "react";
interface MapProps {}
interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}
export const Map: React.FC<MapProps> = ({ onClick, onIdle, children, style, ...options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", onIdle);
      }
    }
  }, [map, onClick, onIdle]);
  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  return (
    <>
      <div className="google-map" ref={ref} style={{ width: "100%", minHeight: "300px" }} />
      {Children.map(children, (child: any) => {
        if (isValidElement(child)) {
          //@ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};
export const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
