import "./style.less";
import { Footer } from "./Footer";
interface SubpageLayoutProps {
  subpage?: string;
  banner?: {
    img: string;
    heading: string;
    subheading: string;
  };
}

export const SubpageLayout: React.FC<SubpageLayoutProps> = ({ children, subpage, banner }) => {
  return (
    <section id="subpage-layout" className={`subpage-layout ${subpage ? subpage : ""}`}>
      {banner ? (
        <div className="subpage-layout-img-wrapper">
          <img src={banner.img} alt="famix-banner" />
          <div className="subpage-layout-img-content">
            <h1>{banner.heading}</h1>
            <p>{banner.subheading}</p>
          </div>
        </div>
      ) : null}

      <div className="subpage-layout-body">
        <div className="subpage-layout-body-content">{children}</div>
      </div>
      <Footer />
    </section>
  );
};
