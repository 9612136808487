import "./style.less";
import { Button, Carousel, Grid } from "antd";
import { slides } from "./data";
import { Slide } from "./Slide";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useGeo } from "../../../../../providers/geolocation";
const { useBreakpoint } = Grid;
interface BannerProps {}
const CustomArrow = (props: any) => {
  return <Button type="primary" {...props} />;
};
export const Banner: React.FC<BannerProps> = () => {
  const breakpoints = useBreakpoint();
  const { voivodeship } = useGeo();
  return (
    <section className="landing-page-banner" aria-label="baner z hasłami o famixie">
      <h2 className="landing-page-banner-subheader">
        Famix <span>{voivodeship === "Małopolskie" ? "Kraków" : "Rzeszów"}</span>
      </h2>
      <Carousel
        effect="fade"
        autoplay
        autoplaySpeed={5000}
        arrows={breakpoints.xl ? true : false}
        nextArrow={<CustomArrow icon={<RightOutlined />} />}
        prevArrow={<CustomArrow icon={<LeftOutlined />} />}
      >
        {slides.map((slide) => (
          <Slide key={slide.id} data={slide} />
        ))}
      </Carousel>

      <Link className="landing-page-banner-link" to="/o-nas" aria-label="Przejdź do storny O nas">
        Zobacz więcej
      </Link>
    </section>
  );
};
